export const FORGOT_PASSWORD_TOAST = {
  ERROR: 'Email này chưa được Đăng ký.',
  SUCCESS: 'Vui lòng kiểm tra lại Email của bạn.',
  WARNING: 'Có lỗi xảy ra, vui lòng nhập lại.'
}

export const RESET_PASSWORD_TOAST = {
  ERROR: 'Kích hoạt khôi phục mật khẩu đã hết hạn. Vui lòng thử lại..',
  SUCCESS: 'Thay đổi mật khẩu thành công.',
  WARNING: 'Có lỗi xảy ra, vui lòng nhập lại.'
}

export const WITHDRAW_TOAST = {
  ERROR: 'Đã có lỗi xảy ra, vui lòng thử lại',
  SUCCESS: 'Phiếu rút đang được duyệt'
}

export const REFRESH_TOAST = {
  ERROR: 'Tài khoản của bạn phiên đăng nhập đã hết hạn hoặc được đăng nhập ở thiết bị khác.'
}

export const DEPOSIT_TOAST = {
  ERROR: 'Đã có lỗi xảy ra, vui lòng thử lại',
  SUCCESS: 'Tạo phiếu nạp thành công.',
  CANCEL: 'Hủy giao dịch thành công.'
}

export const CANCEL_PROMOTION_TOAST = {
  SUCCESS: 'Đã hủy khuyến mãi thành công'
}
export const DISPLAY_NAME_TOAST = {
  ERROR: 'Có lỗi xảy ra, vui lòng nhập lại.',
  SUCCESS: 'Đã cập nhật tên hiển thị thành công.'
}
export const UPDATE_PROFILE = {
  ERROR: 'Xác nhận mật khẩu mới đang không trùng khớp',
  SUCCESS: 'Cập nhật thông tin tài khoản thành công.',
  SUCCESS_PASS: 'Thay đổi mật khẩu thành công.',
  ERROR_SERVER: 'Không thể thao tác, hãy thử lại sau!',
  CHECK_EMAIL: 'Vui lòng kiểm tra email để xác thực.',
  ERROR_EMAIL: 'Xác thực email thất bại.',
  ERROR_BANK: 'Có lỗi xảy ra, vui lòng đăng thử lại.',
  SUCCESS_BANK: 'Tạo tài khoản ngân hàng thành công.'
}
